.App {
  text-align: center;

}

#service {
  padding: 1rem;
  margin: 1rem;
  background: rgba(0,0,0,0.4);
  border: rgba(9,87,121,1) solid 1px;
  box-shadow: #14e2dd 0 2px 7px ;
  font-family: 'Michroma';
  
}

footer {
  background: hsla(0, 0%, 0%, .7);
  min-height: 15vh;
  padding-bottom: 120px;
}

footer a{
  color: #FFF;
  font-size: xx-large;
}

footer a:hover{
  color: #FFF;
  text-decoration: none;
  text-shadow: #14e2dd 5px 5px 3px;
  font-size: xx-large;
  font-weight: 600;
}

header h2, footer h2 {
  -webkit-text-stroke: 1px black;
  font-variant: small-caps;
}
header p {
  -webkit-text-stroke: 0.1px black;
}

header {
  min-height: 14vh;
}

nav {
  z-index: 0;
  position: sticky;
  background: hsla(0, 0%, 100%, .7);
  top: 0;
  font-variant: small-caps;
  z-index: 3;
  opacity: 0;
}




#page-navigation {
  display: none;
}

nav img {
  height: 30px;
  margin: 0 10px 0 10px;
}

#menu-popup {
  cursor: pointer;
  position: fixed;
  top: 10px;
  left: 1rem;
  width: fit-content;
  height: 5rem;
  margin: 0 0;
  z-index: 11;
  opacity: 1;
  text-align: left;
  border: #000 0px solid;
  border-radius: 15px;
}

.popup-backgroud {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 2;
}
.menu-ico::before{
  background-color: rgba(255,255,255, 0.8);
  box-shadow: #14e2dd 0 2px 7px ;
}

#lang-popup {
  cursor: pointer;
  position: fixed;
  top: 105px;
  left: 1rem;
  width: fit-content;
  height: 5rem;
  margin: 0 0;
  z-index: 11;
  opacity: 1;
  text-align: left;
  border: #000 0px solid;
  border-radius: 100%;
}

#menu-popup-ico, #lang-popup-ico {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  background-color: rgba(255,255,255, 0.8);
  box-shadow: #14e2dd 0 2px 7px ;
  border-radius: 100%;
  margin: 0 1rem;
  z-index: 11;
  position: relative;
}




#menu-caption {
  position: absolute;
  bottom: -2rem;
  color: #FFF;
  background-color: rgba(9,87,121,0.8);
  box-shadow: #14e2dd 0 2px 7px ;
  border-radius: 45px;
  width: 5rem;
  text-align: center;
  font-size: x-small;
  z-index: 11;
  
}

.menu-ico {
  width: 4rem;
  height: 4rem;
  margin: 0.5rem;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 11;

}

#menu-ico-home {
  background-image: url('./favicon.png');
  z-index: 11;

  background-size: 70%;
}
#menu-ico-0 {
  background-image: url('./lc_ico/lc_code.svg');
  z-index: 11;

}
#menu-ico-1 {
  background-image: url('./lc_ico/lc_marketing.svg');
  z-index: 11;

}
#menu-ico-2 {
  background-image: url('./lc_ico/lc_graphic.svg');
  z-index: 11;

}
#menu-ico-3 {
  background-image: url('./lc_ico/lc_dron.svg');
  z-index: 11;

}

#lang-no {
  background-image: url('/public/lang/no.png');
  z-index: 11;

}
#lang-en {
  background-image: url('/public/lang/en.png');
  z-index: 11;

}
#lang-pl {
  background-image: url('/public/lang/pl.png');
  z-index: 11;

}

#menu-ico-0:hover {
  animation: animate-ico-0 2s forwards ease infinite;
}
#menu-ico-1:hover {
  animation: animate-ico-1 3s forwards ease infinite;
}
#menu-ico-2:hover {
  animation: animate-ico-2 3s forwards ease infinite;
}
#menu-ico-3:hover {
  animation: animate-ico-3 10s forwards ease infinite;
}

@keyframes animate-ico-0{
  0% {
    background-image: url('./lc_ico/lc_code_00.svg');
  }
 12% {                      /* = fadingin / duration * 100% */
    background-image: url('./lc_ico/lc_code_01.svg');
  }
  24% {                     /* = (fadingin + showing) / duration * 100% */
    background-image: url('./lc_ico/lc_code_02.svg');
  }
  36% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    background-image: url('./lc_ico/lc_code_03.svg');
  }
  48% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    background-image: url('./lc_ico/lc_code_04.svg');
  }
  60% {
    background-image: url('./lc_ico/lc_code_05.svg');
  }
  72% {
    background-image: url('./lc_ico/lc_code_06.svg');
  }
  84% {
    background-image: url('./lc_ico/lc_code_07.svg');
  }  
}

@keyframes animate-ico-1{
  0% {
    background-image: url('./lc_ico/lc_marketing_00.svg');
  }
 16% {                      /* = fadingin / duration * 100% */
    background-image: url('./lc_ico/lc_marketing_01.svg');
  }
  32% {                     /* = (fadingin + showing) / duration * 100% */
    background-image: url('./lc_ico/lc_marketing_02.svg');
  }
  48% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    background-image: url('./lc_ico/lc_marketing_03.svg');
  }
  64% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    background-image: url('./lc_ico/lc_marketing_04.svg');
  }
  70% {
    background-image: url('./lc_ico/lc_marketing_05.svg');
  }
  86% {
    background-image: url('./lc_ico/lc_marketing_06.svg');
  }  
}

@keyframes animate-ico-2{
  0% {
    background-image: url('./lc_ico/lc_graphic_00.svg');
  }
 10% {                      /* = fadingin / duration * 100% */
    background-image: url('./lc_ico/lc_graphic_01.svg');
  }
  19% {                     /* = (fadingin + showing) / duration * 100% */
    background-image: url('./lc_ico/lc_graphic_02.svg');
  }
  28% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    background-image: url('./lc_ico/lc_graphic_03.svg');
  }
  37% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    background-image: url('./lc_ico/lc_graphic_04.svg');
  }
  46% {
    background-image: url('./lc_ico/lc_graphic_05.svg');
  }
  55% {
    background-image: url('./lc_ico/lc_graphic_06.svg');
  }
  64% {
    background-image: url('./lc_ico/lc_graphic_07.svg');
  } 
  73% {
    background-image: url('./lc_ico/lc_graphic_08.svg');
  }   
  82% {
    background-image: url('./lc_ico/lc_graphic_09.svg');
  }   
  91% {
    background-image: url('./lc_ico/lc_graphic_10.svg');
  }   
  99% {
    background-image: url('./lc_ico/lc_graphic_11.svg');
  }   
}
@keyframes animate-ico-3{
  0% {
    background-image: url('./lc_ico/lc_foto_00.svg');
  }
 12% {                      /* = fadingin / duration * 100% */
    background-image: url('./lc_ico/lc_foto_01.svg');
  }
  20% {                      /* = fadingin / duration * 100% */
    transform: rotate(15deg);

  }
  60% {                      /* = fadingin / duration * 100% */
    transform: rotate(-30deg)  scale(1.2);
  }
 92% {                      /* = fadingin / duration * 100% */
    background-image: url('./lc_ico/lc_foto_01.svg');
  }
  94% {
    background-image: url('./lc_ico/lc_foto_00.svg');
  } 
}
.lang-popup-hamburger:hover {
  background-color: rgba(9,87,121,0.8);
  border: rgba(255,255,255, 0.4) 1px solid;
}

#menu-popup-hamburger:hover {
  background-color: rgba(9,87,121,0.8);
  border: rgba(255,255,255, 0.4) 1px solid;
}
#menu-popup-ico:hover, #lang-popup-ico:hover {
  background-color: rgba(9,87,121,0.9);
  border: rgba(255,255,255, 0.4) 1px solid;
}

#menu-popup-hamburger-beam {
  width: 3rem;
  height: 0.3rem;
  background-color:  rgba(9,87,121,1);
  box-shadow: #000 0 2px 7px;
  margin: 0.3rem 0rem;
  border-radius: 15px;
  border: rgba(255,255,255, 0.4) 1px solid;
}

#menu-popup-hamburger, .lang-popup-hamburger{
  width: 5rem;
  height: 5rem;
  padding: 1.3rem 1rem;
  background-color: rgba(9,87,121,0.8);
  box-shadow: #14e2dd 0 2px 7px ;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-align: left;
  border: #000 0px solid;
  border-radius: 100%;
  display: inline-block;
}


.App-header {
  background-color: #282c34;
  opacity: 0.7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.content {
  border: rgba(9,87,121,1) solid 1px;
  box-shadow: #14e2dd 0 2px 7px ;
  background: hsla(0, 0%, 0%, .7);
  align-items: center;
  position: relative;
  padding: 2vw;
  min-height: 12vh;
  max-height: max-content;
  color: #FFF;
}

.content:hover {
  border: rgba(9,87,121,1) solid 1px;
  box-shadow: rgba(9,87,121,1) 0 6px 7px ;
  background: hsla(0, 0%, 0%, .7);
  align-items: center;
  color: #FFF;
  cursor: pointer;
  
}
.content h1{
  z-index: 3;
  font-size: 1.7rem;
  word-break:normal;
  background-color: #FFF;
  padding: 1rem;
  color: rgba(9,87,121,1);
  border: rgba(9,87,121,1) solid 1px;
  box-shadow: #14e2dd 0 2px 7px ;
  font-family: 'Michroma';
  font-weight: 800;
}


.content h3{
  align-self: center;
  font-size: 1.2rem;
  word-break: normal;
  text-align: center;
  
}


.gallery {
  position: relative;
  padding: 2vw;
  min-height: 40vh;
  background: hsla(0, 0%, 0%, .6);
}
.gallery::before {
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  background: hsla(0, 0%, 0%, .5);
  z-index: -1;
}

.notpreface {
  position: relative;
  margin: 0;
  padding: 2vw;
  max-width: 100vw;
  min-width: 80vw;
  min-height: 40vh;
  vertical-align: middle;
  background: hsla(0, 0%, 100%, .6);
}

.notpreface h1 {
  font-weight: 600;
  margin-left: 55px;
  margin-right: 55px;
  font-variant: small-caps;
  display: inline-block;
}

.preface {
  width: 100%;
  position: relative;
  padding: 0 auto;
  vertical-align: middle;
  background: rgba(0,0,0,0.4);
  border: rgba(9,87,121,1) solid 1px;
  border-radius: 15px;
  box-shadow: #14e2dd 0 2px 7px ;
  font-family: 'Michroma';
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  -ms-grid-column-align: center;
  
}


.preface::before {
  border-radius: 15px;
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  background: hsla(0, 0%, 0%, .3);
  z-index: -1;
}


.preface a , .preface a:hover{
  text-decoration: none;
  color: inherit;
  
  
}

.preface p {
  margin-bottom: 0;
  color: #FFF;
  width: 100%;
  text-align: center;
  font-size: 2rem;
}

.preface div:hover img{
  transform: scale(1.3);
}

.preface div {
  margin: auto;
  width: 80%;
  cursor: pointer;
  font-weight: 500;
  font-size: large;
  font-variant: small-caps;
  display: inline-block;
}

.description h3 {
  margin-bottom: 3vh;
  font-size: 140%;
  cursor: pointer;
}

.content h1, .preface h1, .gallery h1 {
  font-variant: small-caps;
  margin: 0 0 5vh 0;
  text-align: center;
}

.picture {
  position: absolute;
  background-color: hsla(0, 0%, 100%, .9);
  left: -10%;
  top: 0%;
  width: 13vw;
  overflow: hidden;
  box-shadow: -2px -2px 4px hsla(0, 0%, 100%, .9);
}

.picture img {
  max-width: 100%;
}

.description {
  text-align: justify;
  font-size: 120%;
}

.footer {
  display: grid;
  grid-template-columns: 20% 45% 32%;
  grid-gap: 10px;
  padding-top: 4vh;
  min-height: 120px;
  position: relative;
  color: #FFF;
  border: #14e2dd solid 0px;
}

footer div p{
  font-variant: small-caps;
  font-size: x-large;
}

#main {
  display: grid;
  grid-gap: 1%;
  padding: 1%;
  position: relative;
}

#finish {
  display: grid;
  grid-template-columns: 48vw 48vw;
  grid-gap: 1vw;
  padding: 1vw;
  position: relative;
}

.footer-contact {
  background-image: url('/public/lc_ico/contact_ico.svg');
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 30%;
  background-size: 4vw;
  margin: 0 15px;
}

.social-ico a:active{
	color: #FFF;
	transform: scale(0.8);
	text-decoration: none;
}

.social-ico p{
  display: inline;
  margin: 0 15px;
}


.social-ico img {
	height: 40px;
	width: 40px;
	margin-bottom: 10px;
}


.footer-logo img:nth-child(1){
	height: 70px;
  margin: 0 30px;
}

.footer-logo img:nth-child(2){  
  display: inline;
  height: 12px;
  filter: invert();
  font-variant: small-caps;
}

div .thumbnail
{
  min-height: 140px;
	align-content: right;
  margin: 0vh 0;
}

.miniature {
  width: 20vw;
  height: 10vw;
  margin: 15px;
  display: inline-grid;
  border:#14e2dd solid 2px;  
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  animation: pageIn 1s 3s forwards;
}
.miniature-logo {
  height: 25vh;
  margin: 5px;
  display: inline-grid;
  animation: pageIn 1s forwards;

}
.miniature-card {
  height: 25vh;
  margin: 5px;
  display: inline-grid;
  animation: pageIn 1s forwards;

}

.miniature:hover {
  width: 20vw;
  height: 10vw;
  margin: 15px;
  display: inline-grid;
  border-color: #000;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.9);
}

.miniature:active {
  width: 20vw;
  height: 10vw;
  margin: 15px;
  display: inline-grid;
  border-color: #000;
  opacity: 1;
  transition: 1s;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
}

@-webkit-keyframes rotateIn{
  from {
    transform: rotate(45deg);
    width: 20vw;
    height: 10vw;
  }
  to { 
    transform: rotate(0deg);
    width: 20vw; 
    height: 10vw;}
}

@keyframes rotateIn{
  from {
    transform: rotate(45deg);
    width: 20vw;
    height: 10vw;
  }
  to { 
    transform: rotate(0deg);
    width: 20vw; 
    height: 10vw;}
}


.gallery {
  opacity: 0;
  animation-name: pageIn;
  animation-duration: 1s;
  animation-delay: 2.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
@-webkit-keyframes pageIn{
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes pageIn{
    from { opacity: 0; }
    to   { opacity: 1; }
}
@-webkit-keyframes slideshowlb{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 23% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
  25% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  48% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  50% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}

@keyframes slideshowlb{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 23% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
  25% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  48% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  50% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}
@-webkit-keyframes slideshowjp{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 1% {                      /* = fadingin / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  23% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  25% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}

@keyframes slideshowjp{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 1% {                      /* = fadingin / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  23% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  25% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}
@-webkit-keyframes slideshowjl{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 74% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
  76% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  98% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}

@keyframes slideshowjl{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 74% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
  76% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  98% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}
@-webkit-keyframes slideshowkl{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 49% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 51% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  73% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  75% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}

@keyframes slideshowkl{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 49% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 51% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  73% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  75% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}
#jp, #jl, #kl, #lb {


}

#jplogo, #jllogo, #kllogo, #lblogo {


}

#jpcard, #jlcard, #klcard, #lbcard {


}

.about-us{
  position: absolute;
  margin: 0;
  padding: 2vw;
  max-width: 97%;
  min-width: 97%;
}

.logo-project{
  height: 13vh;
  margin: 2% 3%;
  z-index: 0;
}


#jlcard{
  opacity: 0;
  display: none;
}
#jpcard{
  opacity: 0;
  animation-name: slideshowjp;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  transform: perspective( 800px ) rotateY( -27deg );
}
#lbcard{
  opacity: 0;
  animation-name: slideshowlb;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  transform: perspective( 800px ) rotateY( -27deg );
}
#klcard{
  opacity: 0;
  animation-name: slideshowkl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  transform: perspective( 800px ) rotateY( -27deg );
}
#jllogo{
  opacity: 0;
  animation-name: slideshowjl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode:forwards;
  animation-iteration-count: infinite;
  

}
#jplogo{
  opacity: 0;
  animation-name: slideshowjp;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  
}
#lblogo{
  opacity: 0;
  animation-name: slideshowlb;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  
}
#kllogo{
  opacity: 0;
  animation-name: slideshowkl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  
}
#jl{
  opacity: 0;
  animation-name: slideshowjl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode:forwards;
  animation-iteration-count: infinite;
}
#jp{
  opacity: 0;
  animation-name: slideshowjp;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
#lb{
  opacity: 0;
  animation-name: slideshowlb;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
#kl{
  opacity: 0;
  animation-name: slideshowkl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

#about3{
  opacity: 0;
  animation-name: slideshowjl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode:forwards;
  animation-iteration-count: infinite;
}
#about0{
  opacity: 0;
  animation-name: slideshowjp;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
#about1{
  opacity: 0;
  animation-name: slideshowlb;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
#about2{
  opacity: 0;
  animation-name: slideshowkl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
.copyright-logo {
  margin-left: 2vw;
  max-height: 20px;
  min-height: 25px;
}

.logo-full {
  margin: 12vh;
  max-height: 25vh;
  animation: pageIn 2s forwards;
  border-radius: 15px 40px 15px 40px;
}

.logotype-nav {
  margin: 10px;
  max-height: 10px;
}

.logotype {
  margin: 5vh;
  max-width: 36vw;
  filter: invert();
}


.lang-bar img {
  height: 33px;
  cursor: pointer;
  margin: 10px;
}
#en {
  margin: 10px;
  transform: perspective(100px) rotateY(-35deg);
}
#no {
  height: 150%;
  margin: 10px;
}
#pl {
  margin: 10px;
  transform: perspective(100px) rotateY(35deg);
}

#no:hover, #en:hover, #pl:hover {
  transform: scale(1.2);
}

#about {
  color: #FFF;
  background:none;
  position: relative;
}


#glitch{
  position:static;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    rgba(0,240,240,.2),
    rgba(0,0,0,0.8)
    ),
  repeating-linear-gradient(
      transparent 0,
      rgba(0,0,0,.2) 3px,
      transparent 6px
      );
      z-index: 3;
    }
    
    
    @media only screen and (max-width: 600px) {
      .description {
        margin: 5vh 1vw 5vh 1vw;
        text-align: justify;
        font-size: 100%;
      }

      .logo-project {
        height: 10vh;
      }

      .preface p {
        margin-bottom: 0;
        color: #FFF;
        width: 100%;
        text-align: center;
        font-size: 1rem;
      }
      .preface img{
        margin-bottom: 0.3rem;
        width: 100%;
      }

      .copyright-logo {
        margin-left: 2vw;
        max-height: 20px;
        min-height: 2px;
      }
      .content h1 {
        font-size: 1.3rem;
      }
      header {
        min-height: 15px;
      }
    
      #finish{
        display: block;
        grid-gap: 1vw;
        padding: 1vw;
        position: relative;
      }
 
    
      .footer {
        display: inline-block;
      }

      nav {
        opacity: 0;
      }

      #menu-popup {
        cursor: pointer;
        position: fixed;
        top: 1%;
        left: auto;
        right: 1%;
        width: 5rem;
        height: fit-content;
        margin: 0 0;
        z-index: 11;
        opacity: 1;
        text-align: left;
        border: #000 0px solid;
        border-radius: 15px;
      }

 
      #menu-popup-ico {
        margin: 2rem 0;
      }
      #service {
        min-height: 20vh;
      }

      
      #lang-popup {
        position: fixed;
        height: fit-content;
        width: 6rem;
        top: 1%;
        left: 2%;
      }

    }