
@keyframes fadeIn {
    from {opacity: 0}
    to {opacity: 1}
  }

#lightbox-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 900;
    background-color: black;
    opacity: 0;
    animation: fadeIn 0.6s 0.2s forwards;
}

#lightbox-close-button{
    color: #FFF;
    font-size: 250%;
    position: absolute;
    top: 2%;
    left: 85%;
    border: #FFF 1px solid;
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
    z-index: 999;
    cursor: pointer;
    opacity: 0.5;
}

#lightbox-close-button:hover{
    opacity: 1;
    transform: scale(1.1);
    color: #FFF;
    border-color: #FFF;
}

#lightbox-close-button:active{
    transform: scale(0.8);
    color: #FF0000;
    border-color: #FFF;
}


#lightbox-close-area{
    position: absolute;
    top: 25%;
    bottom: 0;
    left: 15%;
    right: 15%;
    z-index: 901;
    cursor: pointer;
}
#lightbox-close-area:hover{

}


#lightbox-picture-box {
    height: 10vh;
    transform: translate(0%, 30%);
    z-index: 991;
    opacity: 0;
    animation: fadeIn 0.4s 1s forwards;
}

#lightbox-picture {
    z-index: 991; 
    height: 65vh;
    box-shadow: #FFF 5px 5px 15px;
}

#lightbox-group {
    position: absolute;
    top: 76%;
    left: 17%;
    width: 66%;
    height: fit-content;
    z-index: 991;
    box-shadow: #FFF -2px -2px 7px;
    display: none;
}

#lightbox-group-mini {
    max-width: 10%;
    margin: 2% 1%;
    box-shadow: #FFF 0px 1px 10px;
    cursor: pointer;
}

#lightbox-group-mini:hover {
    transform: scale(1.1);
}


#lightbox-description {
    position: absolute;
    left: 0;
    top: 80%;
    right: 0;
    height: 5+0vh;
    z-index: 999;
    border: 0px solid #FFF;
    opacity: 0;
    animation: fadeIn 0.1s 1.5s forwards;
}


#lightbox-see-before {
    border: #FFF solid 0.5px;
    background-color: rgba(255,255,255,0.5);
    color: #000;
    font-size: 2rem;
    font-weight: 600;
    font-style: italic;
    width: 20%;
    margin: 0 40%;
    cursor: default;
    
}

#picture-counter{
    color: #FFF;
    font-size: 2rem;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    animation: fadeIn 0.1s 1.1s forwards;
}

#arrow-left, #arrow-right{
    color: #FFF;
    font-size: 10rem;
    width: 15%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0%;
    cursor: pointer;
    padding: 20% 0;
    z-index: 991;
    opacity: 0;
    animation: fadeIn 0.1s 1.2s forwards;
}

#arrow-right{
    left: 85%;
}

#arrow-left:active, #arrow-right:active {
    color: #FF0000;
}

#arrow-left p:hover, #arrow-right p:hover {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: arrow-animation 5s;
    animation-delay: 1s;
      /* When the animation is finished, start again */
    animation-iteration-count: infinite;
  }

  @keyframes arrow-animation {
    0% { 
        transform: scale(1);
     }
    25% { 
        transform: scale(0.8);
     }
     50% { 
        transform: scale(0.6);
     }
     75% {
        transform: scale(0.8);
     }
     100% {
        transform: scale(1);
     }
  }

@media only screen and (max-width: 600px) {
    #arrow-left, #arrow-right {
        top: 73%;
        bottom: 0;
        font-size: 5rem;
    }
    #arrow-right {
        left: 75%;
    }
    #arrow-left {
        left: 10%;
    }

    #lightbox-close-area{
        position: absolute;
        top: 0%;
        bottom: 25%;
        left: 15%;
        right: 15%;
        z-index: 901;
        cursor: pointer;
    }

    #lightbox-description {
        position: absolute;
        left: 0;
        top: 85%;
        right: 0;
        height: 5+0vh;
        z-index: 999;
        border: 0px solid #FFF;
    }

    #lightbox-picture {
        height: auto;
        max-height: 40vh;
        max-width: 90%;
        z-index: 991;
        box-shadow: #FFF 5px 5px 15px;
    }
    #lightbox-group {
        position: absolute;
        top: 55%;
        left: 5%;
        width: 90%;
        height: fit-content;
        z-index: 991;
        box-shadow: #FFF -2px -2px 7px;
        display: none;
    }

    #lightbox-picture-box {
        height: 70vh;
        transform: translate(0%, 30%);
        z-index: 991;
    }
    
    #lightbox-group-mini {
        max-width: 15%;
        margin: 5% 15%;
        box-shadow: #FFF 0px 1px 10px;
        cursor: pointer;
    }

    #lightbox-see-before {
        border: #FFF solid 0.5px;
        background-color: rgba(255,255,255,0.3);
        color: #000;
        font-size: 1rem;
        font-weight: 600;
        font-style: italic;
        width: 20%;
        height: 6%;
        margin: 0 40%;
        cursor: default;
    }

    #lightbox-close-button{
        transform: scale(0.7);
    }
}

