.side-menu {
  display: inline-block;
  grid-gap: 1%;
  padding: 1%;
  position: relative;
  background-color: rgba(255,255,255,0.9);
  z-index: 3;
}

.side-menu #menu-popup-ico {
  margin: 5rem 0 1rem 0;
}

#picture-before-after-frame {

  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  
}
#picture-frame {

  width: 100%;
  height: 100%;
}

#article-video {
  width: 640px;
  height: 480px;
  z-index: 11;
  position: relative;
}


#picture {
  background-size: contain;
  background-position-x: 50%;
  background-repeat: no-repeat;
  margin: 5% auto;
  width: 96%;
  min-height: 30vh;
  opacity: 0;
  animation: fadeIn 0.6s 0.2s forwards;
}

#picture-centered {
  background-position-x: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 5% auto;
  width: 96%;
  min-height: 45vh;
  opacity: 0;
  animation: fadeIn 0.6s 0.2s forwards;
}

#picture-caption {
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  width: 96%;
  min-height: 5vh;
  position: relative;
  opacity: 0;
  animation: fadeIn 0.6s 0.2s forwards;
}

#page-break {
  width: fit-content;
  margin: 2rem auto;
  z-index: 1;
  background-color: rgba(255,255,255,0.9);
  padding: 1rem;
  border: rgba(9,87,121,1) solid 1px;
  box-shadow: #14e2dd 0 2px 7px ;
  cursor: pointer;
}

#article-page {
  display: grid;
  grid-gap: 1%;
  padding: 1%;
  position: relative;
  grid-template-columns: 25% 75%;
  width: 100%;
  min-height: 99vh;
}

#article-content {
  position: relative;
  z-index: 1;
  background-color: rgba(255,255,255,0.9);
  padding: 1rem;
  border: rgba(9,87,121,1) solid 1px;
  box-shadow: #14e2dd 0 2px 7px ;
  min-height: 75vh;
}

#article-content p{
  text-align: justify;
  font-size: 1.2rem;
  word-break:normal;
  font-family: 'Michroma';
  font-weight: 800;
  padding: 1.2rem 3rem;
  color: rgba(9,87,121,1);
}

#article-content h1{
  text-align: center;
  font-size: 2rem;
  word-break:normal;
  font-family: 'Michroma';
  font-weight: 800;
  padding: 1.2rem 3rem;
  color: rgba(9,87,121,1);
  font-variant: small-caps;
}


#article-content li{
  text-align: justify;
  font-size: 1.2rem;
  word-break:normal;
  font-family: 'Michroma';
  font-weight: 800;
  padding: 0.2rem 3rem;
  color: rgba(9,87,121,1);
}

#article-content p:first-of-type{
  font-size: 1.5rem;
}

#article-content p:nth-of-type(2){
  font-size: 1.3rem;
}


#logo-box {
  width: 100%;
  height: 30%;
  position: relative;
}


.side-menu-item {
  border: rgba(9,87,121,1) solid 1px;
  box-shadow: #14e2dd 0 2px 7px ;
  background: hsla(0, 0%, 0%, .7);
  align-items: center;
  position: relative;
  padding: .1rem 0;
  max-height: max-content;
  color: #FFF;
}

.side-menu-item:hover {
  border: rgba(9,87,121,1) solid 1px;
  box-shadow: rgba(9,87,121,1) 0 6px 7px ;
  background: hsla(0, 0%, 0%, .7);
  align-items: center;
  color: #FFF;
  cursor: pointer;
  
}
.side-menu-item h1{
  z-index: 3;
  font-size: 1.2rem;
  word-break:normal;
  background-color: #FFF;
  padding: 0.6rem;
  color: rgba(9,87,121,1);
  border: rgba(9,87,121,1) solid 1px;
  box-shadow: #14e2dd 0 2px 7px ;
  font-family: 'Michroma';
  font-weight: 800;
}

.side-menu-item h1:hover{
  background-color: rgba(9,87,121,1);
  color: #FFF;
  border: rgba(9,87,121,1) solid 1px;
  box-shadow: hsla(0, 0%, 0%, .7) 0 6px 7px ;
  font-family: 'Michroma';
  font-weight: 800;
}

.side-menu-item h3{
  align-self: center;
  font-size: 1.0rem;
  word-break: normal;
  text-align: center;
  
}


.side-menu-item-item h3 {
  margin-bottom: 3vh;
  font-size: 100%;
  cursor: pointer;
}

.side-menu-item h1, .preface h1, .gallery h1 {
  font-variant: small-caps;
  margin: 0 0 ;
  text-align: center;
}

.picture {
  position: absolute;
  background-color: hsla(0, 0%, 100%, .9);
  left: -10%;
  top: 0%;
  width: 13vw;
  overflow: hidden;
  box-shadow: -2px -2px 4px hsla(0, 0%, 100%, .9);
}

.picture img {
  max-width: 100%;
}

.side-menu-item-item {
  text-align: justify;
  font-size: 120%;
}


@-webkit-keyframes rotateIn{
  from {
    transform: rotate(45deg);
    width: 20vw;
    height: 10vw;
  }
  to { 
    transform: rotate(0deg);
    width: 20vw; 
    height: 10vw;}
}

@keyframes rotateIn{
  from {
    transform: rotate(45deg);
    width: 20vw;
    height: 10vw;
  }
  to { 
    transform: rotate(0deg);
    width: 20vw; 
    height: 10vw;}
}


.gallery {
  opacity: 0;
  animation-name: pageIn;
  animation-duration: 1s;
  animation-delay: 2.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
@-webkit-keyframes pageIn{
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes pageIn{
    from { opacity: 0; }
    to   { opacity: 1; }
}
@-webkit-keyframes slideshowlb{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 23% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
  25% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  48% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  50% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}

@keyframes slideshowlb{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 23% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
  25% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  48% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  50% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}
@-webkit-keyframes slideshowjp{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 1% {                      /* = fadingin / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  23% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  25% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}

@keyframes slideshowjp{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 1% {                      /* = fadingin / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  23% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  25% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}
@-webkit-keyframes slideshowjl{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 74% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
  76% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  98% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}

@keyframes slideshowjl{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 74% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
  76% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  98% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}
@-webkit-keyframes slideshowkl{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 49% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 51% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  73% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  75% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}

@keyframes slideshowkl{
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 49% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 51% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  73% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
  75% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
 100% {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
}
#jp, #jl, #kl, #lb {


}

#jplogo, #jllogo, #kllogo, #lblogo {


}

#jpcard, #jlcard, #klcard, #lbcard {


}

.about-us{
  position: absolute;
  margin: 0;
  padding: 2vw;
  max-width: 97%;
  min-width: 97%;
}

.logo-project{
  height: 13vh;
  margin: 2% 3%;
}


#jlcard{
  opacity: 0;
  display: none;
}
#jpcard{
  opacity: 0;
  animation-name: slideshowjp;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  transform: perspective( 800px ) rotateY( -27deg );
}
#lbcard{
  opacity: 0;
  animation-name: slideshowlb;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  transform: perspective( 800px ) rotateY( -27deg );
}
#klcard{
  opacity: 0;
  animation-name: slideshowkl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  transform: perspective( 800px ) rotateY( -27deg );
}
#jllogo{
  opacity: 0;
  animation-name: slideshowjl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode:forwards;
  animation-iteration-count: infinite;
  

}
#jplogo{
  opacity: 0;
  animation-name: slideshowjp;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  
}
#lblogo{
  opacity: 0;
  animation-name: slideshowlb;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  
}
#kllogo{
  opacity: 0;
  animation-name: slideshowkl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  
}
#jl{
  opacity: 0;
  animation-name: slideshowjl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode:forwards;
  animation-iteration-count: infinite;
}
#jp{
  opacity: 0;
  animation-name: slideshowjp;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
#lb{
  opacity: 0;
  animation-name: slideshowlb;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
#kl{
  opacity: 0;
  animation-name: slideshowkl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

#about3{
  opacity: 0;
  animation-name: slideshowjl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode:forwards;
  animation-iteration-count: infinite;
}
#about0{
  opacity: 0;
  animation-name: slideshowjp;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
#about1{
  opacity: 0;
  animation-name: slideshowlb;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
#about2{
  opacity: 0;
  animation-name: slideshowkl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
.copyright-logo {
  margin-left: 2vw;
  max-height: 20px;
  min-height: 25px;
}

.logo-full {
  margin: 12vh;
  max-height: 25vh;
  animation: pageIn 2s forwards;
  border-radius: 15px 40px 15px 40px;
}

.logotype-nav {
  margin: 10px;
  max-height: 10px;
}

.logotype {
  margin: 5vh;
  max-width: 36vw;
  filter: invert();
}


.lang-bar img {
  height: 33px;
  cursor: pointer;
  margin: 10px;
}
#en {
  margin: 10px;
  transform: perspective(100px) rotateY(-35deg);
}
#no {
  height: 150%;
  margin: 10px;
}
#pl {
  margin: 10px;
  transform: perspective(100px) rotateY(35deg);
}

#no:hover, #en:hover, #pl:hover {
  transform: scale(1.2);
}

#about {
  color: #FFF;
  background:none;
  position: relative;
  scroll-snap-align: start;
}


#glitch{
  position:static;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    rgba(0,240,240,.2),
    rgba(0,0,0,0.8)
    ),
  repeating-linear-gradient(
      transparent 0,
      rgba(0,0,0,.2) 3px,
      transparent 6px
      );
      z-index: 3;
    }
    
    
    @media only screen and (max-width: 600px) {
      .side-menu-item-item {
        margin: 5vh 1vw 5vh 1vw;
        text-align: justify;
        font-size: 100%;
      }

      #article-video {
        height: 30vw;
        width: 60vw;
      }

      #article-page {
        min-height: 10vh;
      }

      #article-content{
        min-height: 20vh;
      }

      #article-content p{
        text-align: justify;
        font-size: 0.5rem;
        word-break:normal;
        font-family: 'Michroma';
        font-weight: 800;
        padding: 0.6rem 3rem;
        color: rgba(9,87,121,1);
      }
      
      #article-content h1{
        text-align: center;
        font-size: 1.2rem;
        word-break:normal;
        font-family: 'Michroma';
        font-weight: 800;
        padding: 1.2rem 3rem;
        color: rgba(9,87,121,1);
        font-variant: small-caps;
      }
      
      
      #article-content li{
        text-align: justify;
        font-size: 0.6rem;
        word-break:normal;
        font-family: 'Michroma';
        font-weight: 800;
        padding: 0.2rem 3rem;
        color: rgba(9,87,121,1);
      }
      
      #article-content p:first-of-type{
        font-size: 0.7rem;
      }
      
      #article-content p:nth-of-type(2){
        font-size: 0.6rem;
      }

      .logo-project {
        height: 10vh;
      }

      .preface p {
        margin-bottom: 0;
        color: #FFF;
        width: 100%;
        text-align: center;
        font-size: 1rem;
      }
      .preface img{
        margin-bottom: 0.3rem;
        width: 100%;
      }

      .copyright-logo {
        margin-left: 2vw;
        max-height: 20px;
        min-height: 2px;
      }
      .side-menu-item h1 {
        font-size: 1.3rem;
      }
      header {
        min-height: 15px;
      }
    
      #finish{
        display: block;
        grid-gap: 1vw;
        padding: 1vw;
        position: relative;
      }
    
      .side-menu-item {
        min-width: 80vw;
      }
    
      .footer {
        display: inline-block;
      }

      #menu-popup-ico {
        transform: scale(0.8);
        margin: 0 0 1rem 0 ;
      }

      #article-page {
        display: inline;
        grid-gap: 1%;
        padding: 1%;
        position: relative;
        width: 100%;
        min-height: 80%;
      }

      .side-menu #menu-popup-ico {
        display: none;
      }

      #picture-before-after-frame {

        width: 100%;
        display: block;
        margin: 0 0;
        
      }

      #picture {
        padding: 0;
        margin: 0 0;
      }

    }