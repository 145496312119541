  .glitch{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(
      rgba(0,240,240,0.2),
      rgba(9,87,121,0.3)
  ),
  repeating-linear-gradient(
      transparent 0,
      rgba(0,0,0,.2) 3px,
      transparent 6px
  );

  z-index: 3;
  }

  

  @-webkit-keyframes glitchOn{
    1% { 
      z-index: 3;
      background: radial-gradient(
        rgba(0,240,240,0.2),
        rgba(0,0,0,0.8)
    ),
    repeating-linear-gradient(
        transparent 0,
        rgba(0,0,0,.2) 3px,
        transparent 6px
    );
     }
    2%
      { 
      z-index: 999;
     }
    3%
      { 
      z-index: 999;
     }
     4% { 
      z-index: 3;
      background: radial-gradient(
        rgba(9,87,121,0.3),
        rgba(0,0,0,0.8)
    ),
    repeating-linear-gradient(
        transparent 0,
        rgba(0,0,0,.2) 3px,
        transparent 6px
    );
     }
     61%
     { 
     z-index: 3;
    }     
    62%
      { 
      z-index: 999;
     }
     63%
     { 
     z-index: 999;
    }
    64% { 
      z-index: 3;
      background: radial-gradient(
        rgba(0,240,240,0.2),
        rgba(9,87,121,0.3)
    ),
    repeating-linear-gradient(
        transparent 0,
        rgba(0,0,0,.2) 3px,
        transparent 6px
    );
    }
    100% { 
      z-index: 3;
     }    
}

@keyframes glitchOn{
  1% { 
    z-index: 3;
    background: radial-gradient(
      rgba(0,240,240,0.2),
      rgba(0,0,0,0.8)
  ),
  repeating-linear-gradient(
      transparent 0,
      rgba(0,0,0,.2) 3px,
      transparent 6px
  );
   }
  2%
    { 
    z-index: 999;
   }
  3%
    { 
    z-index: 999;
   }
   4% { 
    z-index: 3;
    background: radial-gradient(
      rgba(9,87,121,0.3),
      rgba(0,0,0,0.2)
  ),
  repeating-linear-gradient(
      transparent 0,
      rgba(0,0,0,.2) 3px,
      transparent 6px
  );
   }
   61%
   { 
   z-index: 3;
  }     
  62%
    { 
    z-index: 999;
   }
   63%
   { 
   z-index: 999;
  }
  64% { 
    z-index: 3;
    background: radial-gradient(
      rgba(0,240,240,0.2),
      rgba(9,87,121,0.3)
  ),
  repeating-linear-gradient(
      transparent 0,
      rgba(0,0,0,.2) 3px,
      transparent 6px
  );
  }
  100% { 
    z-index: 3;
   }    
}