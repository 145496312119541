.cookies-popup{
	position: fixed;
	bottom: 0;
	right: 18px;
	left: 18px;
	width: 100vw-36px;
	height: fit-content;
	padding: 20px;
	background-color: #000;
	z-index: 11;
	opacity: 0.7;
	text-align: center;
	border: #000 2px solid;
	animation-name: popup;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}
.cookies-popup p:last-child{
	color: #FFF;
	font-size: 24px;
	margin-top: 1.4rem;
}

.cookies-popup-close-button{
	position: absolute;
	cursor: pointer;
	top: 1rem;
	right: 1rem;
	font-size: 30px;
	font-weight: 500;
	margin-top: 0;
	text-shadow: #FFF 2px 2px 2px;
	color: #FFF;
}

.cookies-popup-disappear{
	position: fixed;
	bottom: 0;
	right: 18px;
	left: 18px;
	width: 100vw-36px;
	height: fit-content;
	padding: 20px;
	background-color: #000;
	z-index: 11;
	opacity: 0;
	text-align: center;
	border: #000 2px solid;
	animation-name: hide;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}



.call-popup{
	position: fixed;
	top: 85%;
	left: 10%;
	width: 5rem;
	height: 5rem;
	margin: 0 2vw;
	background-color: #FFF;
	z-index: 11;
	opacity: 0;
	display: none;
	text-align: left;
	border-radius: 100%;
    border: rgba(9,87,121,1) solid 1px;
    box-shadow: #14e2dd 0 2px 7px ;

}
.call-popup-close-button{
	position: absolute;
	cursor: pointer;
	top: -50px;
	right: -50px;
	font-size: 30px;
	font-weight: 500;
	text-shadow: #FFF 2px 2px 2px;
	visibility: hidden;
}
.call-popup a img:not(.google-ico img){
	margin-right: 10px;
	width: 40px;
	height: 40px;
	filter: brightness(1) invert(1);
}
.google-ico img{
	margin-right: 10px;
	width: 40px;
	height: 40px;
	filter: brightness(0) invert(1);
}

.call-popup p{
	font-size: 11px;
	color: #FFF;
}
.call-popup a{
	color: #FFF;
	font-size: 11px;
	text-decoration: none;
}
.call-popup a:hover{
	text-decoration: none;
	transform: scale(2.0);
	color: #FFF;
}

.call-popup a:active{
	text-decoration: none;
	transform: scale(0.2);
	color: #FFF;
}

.ring {
	margin: auto;
	
	background-image: url('./call_popup/keypad_call_00.svg');
	  /* Start the shake animation and make the animation last for 0.5 seconds */
	  animation: shake 20s;
	  animation-delay: 5s;
  	  /* When the animation is finished, start again */
	  animation-iteration-count: infinite;
	}

	
	
@-webkit-keyframes shake {

	0% {		background-image: url('./call_popup/keypad_call_00.svg');}	
	1% {		background-image: url('./call_popup/keypad_hide_02.svg');}
	2% {		background-image: url('./call_popup/keypad_hide_01.svg');}
	3% {		background-image: url('./call_popup/keypad_hide_00.svg');}
	4% {		background-image: url('./call_popup/keypad_00.svg');}	
	5% {		background-image: url('./call_popup/keypad_00.svg');}
	6% {		background-image: url('./call_popup/keypad_04.svg');}
	7% {		background-image: url('./call_popup/keypad_00.svg');}
	8% {		background-image: url('./call_popup/keypad_08.svg');}
	9% {		background-image: url('./call_popup/keypad_00.svg');}
	10% {		background-image: url('./call_popup/keypad_06.svg');}
	11% {		background-image: url('./call_popup/keypad_00.svg');}
	12% {		background-image: url('./call_popup/keypad_05.svg');}
	13% {		background-image: url('./call_popup/keypad_00.svg');}
	14% {		background-image: url('./call_popup/keypad_01.svg');}
	15% {		background-image: url('./call_popup/keypad_00.svg');}
	16% {		background-image: url('./call_popup/keypad_06.svg');}
	17% {		background-image: url('./call_popup/keypad_00.svg');}
	18% {		background-image: url('./call_popup/keypad_06.svg');}
	19% {		background-image: url('./call_popup/keypad_00.svg');}
	20% {		background-image: url('./call_popup/keypad_06.svg');}
	21% {		background-image: url('./call_popup/keypad_00.svg');}
	22% {		background-image: url('./call_popup/keypad_hide_00.svg');}
	23% {		background-image: url('./call_popup/keypad_hide_01.svg');}
	24% {		background-image: url('./call_popup/keypad_hide_02.svg');}
	25% {		background-image: url('./call_popup/keypad_call_00.svg');}
	26% {		background-image: url('./call_popup/keypad_call_01.svg');}
	27% {		background-image: url('./call_popup/keypad_call_00.svg');}
}
	
@keyframes shake {

	0% {		background-image: url('./call_popup/keypad_call_00.svg');}	
	1% {		background-image: url('./call_popup/keypad_hide_02.svg');}
	2% {		background-image: url('./call_popup/keypad_hide_01.svg');}
	3% {		background-image: url('./call_popup/keypad_hide_00.svg');}
	4% {		background-image: url('./call_popup/keypad_00.svg');}	
	5% {		background-image: url('./call_popup/keypad_00.svg');}
	6% {		background-image: url('./call_popup/keypad_04.svg');}
	7% {		background-image: url('./call_popup/keypad_00.svg');}
	8% {		background-image: url('./call_popup/keypad_08.svg');}
	9% {		background-image: url('./call_popup/keypad_00.svg');}
	10% {		background-image: url('./call_popup/keypad_06.svg');}
	11% {		background-image: url('./call_popup/keypad_00.svg');}
	12% {		background-image: url('./call_popup/keypad_05.svg');}
	13% {		background-image: url('./call_popup/keypad_00.svg');}
	14% {		background-image: url('./call_popup/keypad_01.svg');}
	15% {		background-image: url('./call_popup/keypad_00.svg');}
	16% {		background-image: url('./call_popup/keypad_06.svg');}
	17% {		background-image: url('./call_popup/keypad_00.svg');}
	18% {		background-image: url('./call_popup/keypad_06.svg');}
	19% {		background-image: url('./call_popup/keypad_00.svg');}
	20% {		background-image: url('./call_popup/keypad_06.svg');}
	21% {		background-image: url('./call_popup/keypad_00.svg');}
	22% {		background-image: url('./call_popup/keypad_hide_00.svg');}
	23% {		background-image: url('./call_popup/keypad_hide_01.svg');}
	24% {		background-image: url('./call_popup/keypad_hide_02.svg');}
	25% {		background-image: url('./call_popup/keypad_call_00.svg');}
	26% {		background-image: url('./call_popup/keypad_call_01.svg');}
	27% {		background-image: url('./call_popup/keypad_call_00.svg');}
}

@keyframes sendmail {

	0% {		background-image: url('./call_popup/envelope.svg');}
	5% {		background-image: url('./call_popup/envelope_open.svg');}
	10% {		background-image: url('./call_popup/envelope_open_at.svg');}
	15% {		background-image: url('./call_popup/envelope_at.svg');}
	20% {		background-image: url('./call_popup/envelope_send_finish.svg');}
	25% {		background-image: url('./call_popup/envelope.svg');}	
}

@-webkit-keyframes sendmail {

	0% {		background-image: url('./call_popup/envelope.svg');}
	5% {		background-image: url('./call_popup/envelope_open.svg');}
	10% {		background-image: url('./call_popup/envelope_open_at.svg');}
	15% {		background-image: url('./call_popup/envelope_at.svg');}
	20% {		background-image: url('./call_popup/envelope_send_finish.svg');}
	25% {		background-image: url('./call_popup/envelope.svg');}	
}

.mail-popup{
	position: fixed;
	top: 85%;
	left: 40%;
	width: 5rem;
	height: 5rem;
	margin: 0 2vw;
	background-color: #FFF;
	z-index: 11;
	opacity: 0;
	display: none;
	text-align: left;
	border-radius: 100%;
    border: rgba(9,87,121,1) solid 1px;
    box-shadow: #14e2dd 0 2px 7px ;

}

.mail-popup-close-button{
	position: absolute;
	cursor: pointer;
	top: -50px;
	right: -50px;
	font-size: 30px;
	font-weight: 500;
	text-shadow: #FFF 2px 2px 2px;
	visibility: hidden;
}
.mail-popup a img:not(.google-ico img){
	margin-right: 10px;
	width: 40px;
	height: 40px;
	filter: brightness(1) invert(1);
}
.google-ico img{
	margin-right: 10px;
	width: 40px;
	height: 40px;
	filter: brightness(0) invert(1);
}

.mail-popup p{
	font-size: 11px;
	color: #FFF;
}
.mail-popup a{
	color: #FFF;
	font-size: 11px;
	text-decoration: none;
}
.mail-popup a:hover{
	text-decoration: none;
	transform: scale(2.0);
	color: #FFF;
}

.mail-popup a:active{
	text-decoration: none;
	transform: scale(0.2);
	color: #FFF;
}

.envelope {
	margin: auto;
	
	background-image: url('./call_popup/envelope.svg');
	  /* Start the shake animation and make the animation last for 0.5 seconds */
	  animation: sendmail 20s;
	  animation-delay: 10s;
  	  /* When the animation is finished, start again */
	  animation-iteration-count: infinite;
	}


@keyframes popup {
  from {
  	opacity: 0;
  	display: none;
  }
  to {
  	opacity: 1;
  	display: initial;
  }
}

@keyframes hide-animation {
  from {
  	opacity: 0.7;
  }
  to {
  	display: none;
  	opacity: 0;
  }
}


@media only screen and (max-width: 600px) {
	.call-popup{
		animation-name: popup;
		animation-duration: 3s;
		animation-delay: 3s;
		animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;
		display: block;
	}
	.mail-popup{
		animation-name: popup;
		animation-duration: 3s;
		animation-delay: 3s;
		animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;
		display: block;
	}
}

